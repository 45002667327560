import React, { useState, useEffect } from 'react';
import Header from './components/header.tsx';
import Main from './components/main.tsx';
import Cards from './components/card.tsx';
import GitHubPinnedProjects from './components/pinned.tsx';
import Prices from './components/prices.tsx';
import Contacts from './components/contacts.tsx';

async function loadPosts() {
  const query = `
        query {
          user(login: "Nizarll") {
            pinnedItems(first: 6, types: REPOSITORY) {
              edges {
                node {
                  ... on Repository {
                    id
                    name
                    description
                    stargazerCount
                    forkCount
                    url
                  }
                }
              }
            }
          }
        }
      `;
  const response = await fetch('https://api.github.com/graphql', {
    method: 'POST',
    headers: {
      Authorization: `Bearer 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`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query }),
  });

  const result = await response.json();
  return result.data.user.pinnedItems.edges.map(edge => edge.node);
}


function App() {
  const [currentTab, setCurrentTab] = useState('about me');
  const [posts, setPosts] = useState(['']);
  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    const fetchPinnedRepos = async () => {
      const query = `
        query {
          user(login: "Nizarll") {
            pinnedItems(first: 6, types: REPOSITORY) {
              edges {
                node {
                  ... on Repository {
                    id
                    name
                    description
                    stargazerCount
                    forkCount
                    url
                  }
                }
              }
            }
          }
        }
      `;

      try {
        const response = await fetch('https://api.github.com/graphql', {
          method: 'POST',
          headers: {
            Authorization: `Bearer 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`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ query }),
        });

        const result = await response.json();
        setPosts(result.data.user.pinnedItems.edges.map(edge => edge.node));
      } catch (error) {
        console.error('Error fetching pinned repositories:', error);
      }
    };

    fetchPinnedRepos();
  }, []);

  return (
    <div className="App">
      <section className='flex flex-col h-screen'>
        <Header onTabChange={handleTabChange} />
        {currentTab === 'about me' && <Main />}
        {currentTab === 'portfolio' &&
          <>
            <GitHubPinnedProjects posts={posts} />
            <Cards />
          </>
        }
        {currentTab === 'prices' && <Prices />}
        {currentTab === 'contact' && <Contacts />}
      </section>
      {/* Render nothing or a placeholder if currentTab is not 'about me' or 'portfolio' */}
    </div>
  );
}

export default App;

