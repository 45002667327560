import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import vid_1 from '../resources/vid-1.mp4';
import vid_2 from '../resources/vid-2.mp4';
import vid_3 from '../resources/vid-3.mp4';
import vid_4 from '../resources/vid-4.mp4';

const data = [
  {
    url: vid_1,
    title: 'Combat'
  },
  {
    url: vid_2,
    title: 'Breathing style'
  },
  {
    url: vid_3,
    title: 'Blood skill'
  },
  {
    url: vid_4,
    title: 'Bleach skill'
  }
]

export default function Cards() {
  const [videoUrls, setVideoUrls] = useState([]);
  const [starredRepos, setStarredRepos] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMobile = window.innerWidth <= 768;

  const cardVariants = {
    hidden: { opacity: 0, y: isMobile ? 0 : 50, x: isMobile ? 50 : 0 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      x: 0,
      transition: {
        delay: i * 0.1,
        type: 'spring',
        stiffness: 150,
        damping: 20,
        mass: 0.5,
      },
    }),
  };

  return (
    <>
      <h2 className="mx-[2.8rem] text-2xl font-bold drop-shadow-lg my-8">Roblox Projects</h2>
      <div className="flex flex-wrap justify-center items-center mx-6">
        {data.map((video: any, index) => (
          <motion.a
            key={index}
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            custom={index}
            className="block max-w-sm w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 m-4 transition-all ease-in-out duration-200 hover:scale-110 hover:drop-shadow-3xl outline outline-3 outline-black"
          >
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 text-center">{video.title}</h5>
            <div className="w-full h-full drop-shadow-lg">
              <video width="1024" height="1024" controls className="rounded-md w-full h-full">
                <source src={video.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </motion.a>
        ))}
      </div>
    </>
  );
}

