import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';

const GitHubPinnedProjects = ({ posts }) => {

  const variant = {
    hidden: { opacity: 0, y: -50 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        type: "spring",
        stiffness: 300,
        damping: 9,
        mass: .6,
      },
    }),
  };
  return (
    <div>
      <h2 className="mx-[2.8rem] text-2xl font-bold drop-shadow-lg my-8">Github Projects</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-6">
        {posts.map((repo: any, index) => {
          return (
            <motion.div
              key={repo.id}
              variants={variant}
              initial="hidden"
              animate="visible"
              custom={index}
              className="m-4 p-4 rounded shadow-md hover:shadow-lg transition-shadow duration-300 outline outline-3 outline-black hover:scale-110"
            >
              <h3 className="text-xl font-bold">
                <a href={repo.url} target="_blank" rel="noopener noreferrer">
                  {repo.name}
                </a>
              </h3>
              <p>{repo.description}</p>
              <p>
                ⭐ {repo.stargazerCount} | Forks: {repo.forkCount}
              </p>
              <p>
                <a href={repo.url} target="_blank" rel="noopener noreferrer">
                  View on GitHub
                </a>
              </p>
            </motion.div>
          );
        })
        }
      </div>
    </div>
  );
};

export default GitHubPinnedProjects;

