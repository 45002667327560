import React from "react"

export default function Prices() {
  return (
    <div>
      <h1 className="mx-[2.8rem] text-2xl font-bold drop-shadow-lg my-8">Prices</h1>
      <div className="flex flex-row items-center justify-center">
        <div className="hover:scale-110 transition-all ease-in-out duration-200 hover:drop-shadow-xl m-8 w-96 h-96 shadow-xl rounded-lg outline outline-3 outline-black flex flex-col items-center">
          <h1 className="mx-[2.8rem] text-2xl font-bold mt-8">Basic Script</h1>
          <h1 className="mx-[2.8rem] text-lg mb-2 mt-2 text-gray-600">basic script like Skills or Ui</h1>
          <div className="w-full h-[2px] mt-8 bg-black"></div>
          <h1 className="mx-[2.8rem] text-3xl font-bold mt-1">$15 - $25 USD</h1>
          <h1 className="mx-[2.8rem] text-xl font-bold mt-1">50% Upfront</h1>
          <div className="w-full h-[3px] mt-2 bg-black"></div>
          <h1 className="mx-[2.8rem] text-lg mt-8">High Quality</h1>
          <h1 className="mx-[2.8rem] text-lg mt-1">1-2 Days Delivery</h1>
          <h1 className="mx-[2.8rem] text-lg mt-1">Up to 10 Changes</h1>
        </div>
        <div className="hover:scale-110 transition-all ease-in-out duration-200 hover:drop-shadow-xl m-8 w-96 h-96 shadow-xl rounded-lg outline outline-3 outline-black flex flex-col items-center">
          <h1 className="mx-[2.8rem] text-2xl font-bold mt-8">System</h1>
          <h1 className="mx-[2.8rem] text-lg mb-2 mt-2 text-gray-600">System like combat or skill system</h1>
          <div className="w-full h-[2px] mt-8 bg-black"></div>
          <h1 className="mx-[2.8rem] text-3xl font-bold mt-1">$80 - $220 USD</h1>
          <h1 className="mx-[2.8rem] text-xl font-bold mt-1">50% Upfront</h1>
          <div className="w-full h-[3px] mt-2 bg-black"></div>
          <h1 className="mx-[2.8rem] text-lg mt-8">High Quality</h1>
          <h1 className="mx-[2.8rem] text-lg mt-1">1 Day - 1 Week Delivery</h1>
          <h1 className="mx-[2.8rem] text-lg mt-1">Unlimited Changes</h1>
        </div>
        <div className="hover:scale-110 transition-all ease-in-out duration-200 hover:drop-shadow-xl m-8 w-96 h-96 shadow-xl rounded-lg outline outline-3 outline-black flex flex-col items-center">
          <h1 className="mx-[2.8rem] text-2xl font-bold mt-8">Full Game Scripting</h1>
          <h1 className="mx-[2.8rem] text-lg mb-2 mt-2 text-gray-600">Scripting of an entire game</h1>
          <div className="w-full h-[2px] mt-8 bg-black"></div>
          <h1 className="mx-[2.8rem] text-3xl font-bold mt-1">$800 - $3300 USD</h1>
          <h1 className="mx-[2.8rem] text-xl font-bold mt-1">Per Task</h1>
          <div className="w-full h-[3px] mt-2 bg-black"></div>
          <h1 className="mx-[2.8rem] text-lg mt-8">High Quality</h1>
          <h1 className="mx-[2.8rem] text-lg mt-1">3 - 12 Weeks Delivery</h1>
          <h1 className="mx-[2.8rem] text-lg mt-1">Unlimited Changes</h1>
        </div>
      </div>
    </div>
  );
}
