import React from 'react';
import { motion } from 'framer-motion';

// Define a type for the props including the callback
interface HeaderProps {
  onTabChange: (tab: string) => void;
}

const Header: React.FC<HeaderProps> = ({ onTabChange }) => {
  // Define the button variants for animation
  const buttonVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        type: "spring",
        stiffness: 300,
        damping: 9,
        mass: .6,
      },
    }),
  };

  const handleButtonClick = (tab: string) => {
    onTabChange(tab);
  };

  return (
    <section className="flex flex-row items-center justify-center pt-8 mx-8 font-semibold">
      <div className="pt-3 py-3 rounded-full">
        <motion.button
          className="transition-all hover:rounded-3xl duration-300 ease-out hover:px-6 hover:bg-black hover:text-white text-black text-md mx-4 px-3 py-1 outline outline-3 outline-black rounded-lg"
          variants={buttonVariants}
          initial="hidden"
          animate="visible"
          custom={0}
          onClick={() => handleButtonClick('about me')}
        >
          about me
        </motion.button>
        <motion.button
          className="transition-all hover:rounded-3xl duration-300 ease-out hover:px-6 hover:bg-black hover:text-white text-black text-md mx-4 px-3 py-1 outline outline-3 outline-black rounded-lg"
          variants={buttonVariants}
          initial="hidden"
          animate="visible"
          custom={1}
          onClick={() => handleButtonClick('portfolio')}
        >
          portfolio
        </motion.button>
        <motion.button
          className="transition-all hover:rounded-3xl duration-300 ease-out hover:px-6 hover:bg-black hover:text-white text-black text-md mx-4 px-3 py-1 outline outline-3 outline-black rounded-lg"
          variants={buttonVariants}
          initial="hidden"
          animate="visible"
          custom={2}
          onClick={() => handleButtonClick('prices')}
        >
          prices
        </motion.button>
        <motion.button
          className="transition-all hover:rounded-3xl duration-300 ease-out hover:px-6 hover:bg-black hover:text-white text-black text-md mx-4 px-3 py-1 outline outline-3 outline-black rounded-lg"
          variants={buttonVariants}
          initial="hidden"
          animate="visible"
          custom={3}
          onClick={() => handleButtonClick('contact')}
        >
          contact
        </motion.button>
      </div>
    </section>
  );
};

export default Header;

