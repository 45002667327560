import React from "react";
import { motion } from "framer-motion";
import { PiDiscordLogoBold } from "react-icons/pi";

export default function Contacts() {
  return (
    <section className="flex flex-col items-center justify-center w-screen my-12 h-full">
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div className="flex flex-col w-full items-center">
          <div className="flex flex-col items-center justify-center w-full">
            <motion.div
              className="flex flex-col items-center justify-center w-full my-4 text-center"
              initial={{ x: 200, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: 200, damping: 80 }}
            >
              <div className="flex flex-col items-center">
                <p className="text-4xl md:text-7xl text-black font-extrabold">Join The Server</p>
                <p className="text-lg md:text-2xl mt-2 md:mt-4 text-gray-600">nizar's portfolio server</p>
              </div>
              <a
                href="https://discord.gg/Ca8RyxKZG8"
                className="w-[50%] md:w-48 h-auto bg-black transition-all hover:rounded-3xl duration-300 ease-out hover:px-6 hover:bg-white hover:text-black text-white text-md px-3 py-1 outline outline-4 outline-black rounded-lg my-4 flex items-center justify-center"
              >
                <PiDiscordLogoBold className="w-5 h-auto md:w-7 md:h-auto" />
                <p className="ml-2 md:ml-3 font-semibold">Discord Server</p>
              </a>
            </motion.div>
          </div>
        </div>
        <div className="flex flex-col w-full items-center">
          <div className="flex flex-col items-center justify-center w-full">
            <motion.div
              className="flex flex-col items-center justify-center w-full my-4 text-center"
              initial={{ x: -200, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: 200, damping: 80 }}
            >
              <div className="flex flex-col items-center">
                <p className="text-4xl md:text-7xl text-black font-extrabold">Dm me !</p>
                <p className="text-lg md:text-2xl mt-2 md:mt-4 text-gray-600">nizar's discord username</p>
              </div>
              <button className="w-[50%] md:w-48 h-auto bg-black transition-all hover:rounded-3xl duration-300 ease-out hover:px-6 hover:bg-white hover:text-black text-white text-md px-3 py-1 outline outline-4 outline-black rounded-lg my-4 flex items-center justify-center">
                <PiDiscordLogoBold className="w-5 h-auto md:w-7 md:h-auto" />
                <p className="ml-2 md:ml-3 font-semibold">nizartheverycooldev</p>
              </button>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}
